<template>
  <div class="bg-primary_50">
    <the-header></the-header>
    <!-- menu -->
    <admin-menu></admin-menu>
    <router-view></router-view>
  </div>
</template>
<script>
import TheHeader from "@/components/layouts/TheHeader.vue";
import AdminMenu from "@/components/admin/AdminMenu.vue";

export default {
  components: { TheHeader, AdminMenu },
  setup() {},
};
</script>
