import LnadingPage from "../views/common/LnadingPage.vue";
import SignIn from "../views/common/SignIn.vue";
import SetPassword from "../views/common/SetPassword.vue";
import ForgotPassword from "../views/common/ForgotPassword.vue";
import AllSkills from "../views/common/AllSkills.vue";

export default [
  {
    path: "/",
    name: "LnadingPage",
    component: LnadingPage,
  },
  {
    name: "SignIn",
    path: "/signin",
    component: SignIn,
    props: true,
  },
  {
    path: "/set-password/:token",
    component: SetPassword,
    props: true,
  },
  {
    path: "/fogot-password",
    component: ForgotPassword,
    props: true,
  },
  {
    path: "/all-skills",
    component: AllSkills,
    props: true,
  },
];
