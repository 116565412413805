<template>
  1<!-- VIDEOS -->
  <section class="text-black body-font">
    <div class="px-4 sm:px-10 py-10 mx-auto">
      <div class="flex flex-col text-center w-full mb-10">
        <h2 class="mb-2 block text-2xl font-semibold text-secondary">
          {{ $t("learnSoftSkills1") }}
        </h2>
        <h1 class="mb-8 text-3xl font-bold text-dark sm:text-4xl">
          {{ $t("onlineVideos") }}
        </h1>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
        <video-viewer
          v-for="videoDetail in videoList"
          :key="videoDetail.id"
          :videoDetails="videoDetail"
        ></video-viewer>
      </div>
    </div>
  </section>
</template>
<script>
import { ref } from "@vue/reactivity";
import { onlineVideoList } from "../../../online-videos";
import VideoViewer from "./VideoViewer.vue";
export default {
  components: { VideoViewer },
  setup() {
    const videoList = ref(onlineVideoList);

    return {
      videoList,
    };
  },
};
</script>
