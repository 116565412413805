<template>
  <section
    class="relative flex flex-wrap lg:items-left bg-gradient-to-r from-primary_900 via-secondary_700 to-purple-600"
  >
    <div class="w-full px-4 py-12 lg:w-1/2 sm:px-6 lg:px- sm:py-16 lg:py-8">
      <div class="max-w-lg text-left">
        <h1 class="text-2xl font-bold sm:text-3xl text-white">
          {{ $t("getstart") }}
        </h1>
      </div>

      <form
        @submit.prevent="signInUser"
        class="w-full mt-8 mb-0 space-y-4"
        autocomplete="off"
      >
        <div>
          <label for="email" class="sr-only">{{ $t("email") }}</label>

          <div class="relative">
            <input
              type="email"
              class="w-full p-4 pr-12 text-sm border-pr rounded-none shadow-sm"
              :class="{
                'border-2 border-red-500': !loginData.userName.isValid,
              }"
              :placeholder="$t('enterEmail')"
              id="userName"
              name="userName"
              v-model.trim="loginData.userName.val"
              @blur="clearValidity('userName')"
            />

            <span class="absolute inset-y-0 inline-flex items-center right-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-5 h-5 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                />
              </svg>
            </span>
          </div>
        </div>

        <div>
          <label for="password" class="sr-only">{{ $t("password") }}</label>
          <div class="relative">
            <input
              ref="password"
              :type="types.value"
              class="w-full p-4 pr-12 text-sm rounded-none shadow-sm"
              :class="{
                'border-2 border-red-500': !loginData.password.isValid,
              }"
              :placeholder="$t('enterPassword')"
              id="password"
              name="password"
              v-model="loginData.password.val"
              @blur="clearValidity('password')"
            />

            <span
              class="absolute inset-y-0 inline-flex items-center right-4"
              @click="togglePassword"
            >
              <img
                v-if="types.value == 'password'"
                class="h-5 w-5"
                src="../../assets/images/hide.svg"
                alt=""
              />
              <svg
                v-if="types.value == 'text'"
                xmlns="http://www.w3.org/2000/svg"
                class="w-5 h-5 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                />
              </svg>
            </span>
          </div>
        </div>

        <div class="flex items-center justify-between">
          <p class="text-sm text-gray-100">
            {{ $t("fogotPassword") }}
            <a href="#" class="underline"> {{ $t("recoverNow") }}</a>
          </p>

          <button
            type="submit"
            class="px-12 py-3 text-sm font-lg text-white rounded-none shadow bg-primary_900 sm:w-auto active:bg-primary_700 hover:bg-primary_700 focus:outline-none focus:ring"
            href="login.html"
          >
            {{ $t("submit") }}
          </button>
        </div>
      </form>
    </div>

    <div class="relative w-full h-52 sm:h-48 lg:w-1/2 lg:h-96">
      <img
        alt="Welcome"
        src="https://images.unsplash.com/photo-1663743555914-4c948542f757?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=874&q=80"
        class="absolute object-cover w-full h-full"
      />
    </div>
  </section>

  <p v-if="!formIsValid">{{ invalidmessage }}</p>
  <base-spinner v-if="isLoading"></base-spinner>
</template>

<script>
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import BaseSpinner from "../UI/BaseSpinner.vue";

import { UserRoles } from "../../app-constants";
import { reactive, ref } from "@vue/reactivity";

import { useI18n } from "vue-i18n";

export default {
  components: {
    BaseSpinner,
  },
  emits: ["update-logged-user"],

  setup() {
    const router = useRouter();
    const store = useStore();
    const { t } = useI18n();
    const types = reactive({
      value: "password",
    });

    const loginData = reactive({
      userName: { val: "", isValid: true },
      password: { val: "", isValid: true },
    });
    const formIsValid = ref(true);
    const invalidmessage = ref(null);
    const isLoading = ref(false);

    function clearValidity(input) {
      loginData[input].isValid = true;
    }

    function validateFrom() {
      formIsValid.value = true;
      if (loginData.userName.val == "") {
        loginData.userName.isValid = false;
        formIsValid.value = false;
      }
      if (loginData.password.val == "") {
        loginData.password.isValid = false;
        formIsValid.value = false;
      }
    }
    const togglePassword = () => {
      types.value = types.value == "password" ? "text" : "password";
    };

    async function signInUser() {
      validateFrom();
      if (!formIsValid.value) {
        invalidmessage.value = "Required  fields missing";
        return;
      }
      isLoading.value = true;
      try {
        await store.dispatch("user/login", {
          userName: loginData.userName.val,
          password: loginData.password.val,
        });
        const loggedUserRole = store.getters["user/loggedUserRole"];

        isLoading.value = false;
        goToSystem(loggedUserRole);
      } catch (error) {
        isLoading.value = false;
        alert(t("failedToAuthenticate"));
        formIsValid.value = false;
        invalidmessage.value = t("failedToAuthenticate");
      }
    }

    const goToSystem = async (loggedUserRole) => {
      //await store.dispatch("user/updateNotification");
      //await store.dispatch("user/updateMessages");
      if (loggedUserRole == UserRoles.ADMIN) {
        router.replace("/admin");
      } else if (loggedUserRole == UserRoles.USER) {
        router.replace("/user");
      }
    };

    return {
      loginData,
      formIsValid,
      invalidmessage,
      clearValidity,
      signInUser,
      isLoading,
      types,
      togglePassword,
      t,
    };
  },
};
</script>

<style>
.errors {
  border-color: rgba(231, 91, 91, 0.3);
}
</style>
