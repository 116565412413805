<template>
  <header class="flex justify-between mx-4 text-secondary_900 py-4 h-20">
    <img class="w-32" src="../../assets/images/logo.svg" alt="" />
    <ul class="hidden md:flex flex-row items-center align-middle gap-4">
      <li>
        <router-link to="/" class="bg-slate-300 py-2 px-6">{{
          $t("home")
        }}</router-link>
      </li>
      <li>
        <a href="" class="">{{ $t("about") }}</a>
      </li>
      <li>
        <a href="" class="">{{ $t("contact") }}</a>
      </li>
      <li>
        <a href="" class="">{{ $t("team") }}</a>
      </li>
      <li>
        <router-link
          class="block w-full px-12 py-3 text-sm font-lg text-white rounded shadow bg-secondary_900 sm:w-auto active:bg-secondary_700 hover:bg-secondary_700 focus:outline-none focus:ring"
          :to="{
            name: 'SignIn',
          }"
        >
          {{ $t("logIn") }}
        </router-link>
      </li>
    </ul>
    <button class="md:hidden">
      <router-link
        class="block w-full px-12 py-3 text-sm font-lg text-white rounded shadow bg-secondary_900 sm:w-auto active:bg-secondary_700 hover:bg-secondary_700 focus:outline-none focus:ring"
        :to="{
          name: 'SignIn',
        }"
      >
        {{ $t("logIn") }}
      </router-link>
    </button>
  </header>
</template>
