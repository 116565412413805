<template>
  <div class="mb-10 pb-10 px-2 sm:px-10" :id="categoty.id">
    <div class="max-w-xl mx-4 text-left mt-5">
      <span
        class="uppercase mb-2 text-lg font-semibold text-secondary"
        id="category1"
      >
        {{ $t(categoty.nameKey) }}
      </span>
      <h1 class="mb-2 text-3xl font-bold text-dark sm:text-4xl">
        {{ $t('learnSoftSkills') }}
      </h1>
    </div>
    <div class="px-4 py-15 mx-auto">
      <div class="grid md:grid-cols-2 sm:grid-cols-1 mt-5">
        <div class="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-3">
          <!-- CARD 1 -->
          <div
            class="card hover:bg-slate-100 rounded-sm"
            v-for="(skill, index) in categoty.skills"
            :key="skill.id"
            @click="selectSkill(index)"
          >
            <h3 class="text-2xl font-bold text-gray_600 p-3 uppercase">
              {{ $t(skill.codeKey) }}
            </h3>
            <div class="flex justify-center">
              <span
                class="p-3 bg-category_1 inline-block rounded-full w-32 h-32 shadow-md"
                :class="categoty.colourClass"
              >
                <img
                  :src="getImgUrl(skill.image)"
                  alt=""
                  class="self-center fill-current w-24 h-24 mx-auto p-4 color-white"
                />
              </span>
            </div>

            <h1
              class="text-md md:text-base py-2 text-center text-gray_600 uppercase"
            >
              {{ $t(skill.namekey) }}
            </h1>
            <div class="flex justify-center text-gray_500">
              <label
                @click="selectSkill(index)"
                :for="'my-modal' + categoty.id"
                class="uppercase md:hidden px-4 py-1 border mb-2 border-slate-500 rounded-full hover:bg-slate-500 hover:text-white text-xs focus:outline-none focus:ring"
                >{{ $t('learn') }}</label
              >
            </div>
          </div>
        </div>
        <div class="hidden md:grid grid-cols-1 ml-2">
          <div class="card ml-1 text-slate-600 p-6 shadow-xl rounded-sm">
            <div class="lg:flex justify-start items-center md:flex flex-col">
              <div class="flex justify-center">
                <span
                  class="inline-block rounded-full w-24 h-24 shadow-md"
                  :class="categoty.colourClass"
                >
                  <img
                    :src="getImgUrl(selectedSkill.image)"
                    alt=""
                    class="self-center fill-current w-24 h-24 mx-auto p-6 color-category_1"
                  />
                </span>
              </div>
              <h3
                class="text-lg font-bold p-3 text-gray-600 display-block ml-2 uppercase"
              >
                {{ $t(selectedSkill.namekey) }}
              </h3>
              <hr />
            </div>

            <p class="py-4 text-justify">
              {{ $t(selectedSkill.descriptionKey) }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- MODEL-->
  <input type="checkbox" :id="'my-modal' + categoty.id" class="modal-toggle" />
  <div class="modal">
    <div class="modal-box relative rounded-sm bg-white text-gray_600">
      <label
        :for="'my-modal' + categoty.id"
        class="btn btn-sm btn-circle absolute right-2 top-2"
        >✕</label
      >
      <div class="flex justify-between">
        <h3 class="text-2xl font-bold uppercase">
          {{ $t(selectedSkill.namekey) }}
        </h3>
        <img class="w-24" :src="getImgUrl(selectedSkill.image)" alt="" />
      </div>
      <p class="py-4">
        {{ $t(selectedSkill.descriptionKey) }}
      </p>
    </div>
  </div>
</template>
<script>
import { reactive } from '@vue/reactivity';
export default {
  props: ['categoty'],
  setup(props) {
    const selectedSkill = reactive({
      image: props.categoty.skills[0].image,
      namekey: props.categoty.skills[0].namekey,
      descriptionKey: props.categoty.skills[0].descriptionKey,
    });

    const selectSkill = (index) => {
      selectedSkill.image = props.categoty.skills[index].image;
      selectedSkill.namekey = props.categoty.skills[index].namekey;
      selectedSkill.descriptionKey =
        props.categoty.skills[index].descriptionKey;
    };
    const getImgUrl = (img) => {
      return require('@/assets/images/' + img);
    };
    return {
      getImgUrl,
      selectedSkill,
      selectSkill,
    };
  },
};
</script>
