<template>
  <div>
    <section class="relative flex flex-wrap lg:items-left bg-slate-300">
      <div class="w-full px-4 py-12 lg:w-1/2 sm:px-6 lg:px- sm:py-16 lg:py-8">
        <div class="max-w-lg text-left">
          <h1 class="text-2xl font-bold sm:text-3xl text-slate-500">
            {{ $t("addUser") }}
          </h1>
        </div>

        <form @submit.prevent="saveUser" class="w-full mt-8 mb-0 space-y-4">
          <div>
            <label for="name" class="sr-only">{{ $t("name") }}</label>
            <div class="relative">
              <input
                id="name"
                type="text"
                class="w-full p-4 pr-12 text-sm border-pr rounded-none shadow-sm"
                :class="{
                  'wy-field-validate-error': !userData.name.isValid,
                }"
                :placeholder="$t('enterName')"
                @blur="clearValidity('name')"
                v-model.trim="userData.name.val"
              />
              <p
                v-if="!userData.name.isValid"
                class="mt-2 text-sm text-red-600 dark:text-red-500"
              >
                {{ userData.name.msg }}
              </p>
            </div>
          </div>

          <div>
            <label for="email" class="sr-only">{{ $t("email") }}</label>

            <div class="relative">
              <input
                id="email"
                type="email"
                class="w-full p-4 pr-12 text-sm border-pr rounded-none shadow-sm"
                :class="{
                  'wy-field-validate-error': !userData.email.isValid,
                }"
                :placeholder="$t('enterEmail')"
                @blur="clearValidity('email')"
                v-model.trim="userData.email.val"
              />
              <p
                v-if="!userData.email.isValid"
                class="mt-2 text-sm text-red-600 dark:text-red-500"
              >
                {{ userData.email.msg }}
              </p>
            </div>
          </div>

          <div>
            <label for="institute" class="sr-only">{{ $t("institute") }}</label>
            <div class="relative">
              <input
                id="institute"
                type="text"
                class="w-full p-4 pr-12 text-sm border-pr rounded-none shadow-sm"
                :class="{
                  'wy-field-validate-error': !userData.institute.isValid,
                }"
                :placeholder="$t('enterInstitute')"
                @blur="clearValidity('institute')"
                v-model.trim="userData.institute.val"
              />
              <p
                v-if="!userData.institute.isValid"
                class="mt-2 text-sm text-red-600 dark:text-red-500"
              >
                {{ userData.institute.msg }}
              </p>
            </div>
          </div>

          <div>
            <label for="userRole" class="sr-only">{{ $t("role") }}</label>
            <div class="relative">
              <select
                id="userRole"
                class="w-full p-4 pr-12 text-sm border-pr rounded-none shadow-sm"
                :class="{
                  'wy-field-validate-error': !userData.userRole.isValid,
                }"
                :placeholder="$t('enterRole')"
                @blur="clearValidity('userRole')"
                v-model.trim="userData.userRole.val"
              >
                <option value="">{{ $t("enterRole") }}</option>
                <option value="USER">{{ $t("user") }}</option>
                <option value="ADMIN">{{ $t("admin") }}</option>
              </select>
              <p
                v-if="!userData.userRole.isValid"
                class="mt-2 text-sm text-red-600 dark:text-red-500"
              >
                {{ userData.userRole.msg }}
              </p>
            </div>
          </div>

          <div class="flex items-center justify-between">
            <button
              class="uppercase px-12 py-3 text-sm font-lg text-white rounded-none shadow bg-primary_900 sm:w-auto active:bg-primary_700 hover:bg-primary_700 focus:outline-none focus:ring"
              type="submit"
            >
              {{ $t("save") }}
            </button>
          </div>
          <p v-if="!formIsValid" class="text-red-600">
            {{ invalidmessage }}
          </p>
        </form>
      </div>

      <div class="relative w-full h-52 sm:h-48 lg:w-1/2 lg:h-96 mt-8">
        <img
          alt="Welcome"
          src="https://images.unsplash.com/photo-1520607162513-77705c0f0d4a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80"
          class="absolute object-cover w-full h-full"
        />
      </div>
    </section>
  </div>
</template>
<script>
import { reactive, ref } from "@vue/reactivity";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { backendURL } from "@/app-constants";
import { useToast } from "primevue/usetoast";
import { onMounted, watch } from "@vue/runtime-core";
export default {
  emits: ["refresh-list"],
  props: ["userId"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const toast = useToast();
    const store = useStore();

    const userData = reactive({
      id: { val: null, isValid: true, msg: "" },
      name: { val: "", isValid: true, msg: "" },
      email: { val: "", isValid: true, msg: "" },
      userRole: { val: "", isValid: true, msg: "" },
      institute: { val: "", isValid: true, msg: "" },
    });
    const formIsValid = ref(true);
    const invalidmessage = ref(null);
    const isLoading = ref(false);

    function clearValidity(input) {
      userData[input].isValid = true;
      userData[input].msg = "";
    }

    function validateFrom() {
      formIsValid.value = true;
      if (userData.name.val == "") {
        userData.name.isValid = false;
        //userData.name.msg = t("enterName");
        formIsValid.value = false;
      }

      if (userData.userRole.val == "") {
        userData.userRole.isValid = false;
        //userData.userRole.msg = t("enterRole");
        formIsValid.value = false;
      }

      if (userData.email.val == "") {
        userData.email.isValid = false;
        //userData.email.msg = t("enterEmail");
        formIsValid.value = false;
      }

      if (userData.institute.val == "") {
        userData.institute.isValid = false;
        //userData.institute.msg = t("enterInstitute");
        formIsValid.value = false;
      }
    }

    const saveUser = async () => {
      validateFrom();
      if (!formIsValid.value) {
        isLoading.value = false;
        invalidmessage.value = t("required_fields_missing");
        return;
      }
      try {
        const token = store.getters["user/token"];
        let url;
        if (!props.userId) {
          url = backendURL + "/api/user/admin-add-user";
        } else {
          url = backendURL + "/api/user/admin-user-update";
        }
        const user = {
          name: userData.name.val,
          email: userData.email.val,
          institute: userData.institute.val,
          role: userData.userRole.val,
          userId: userData.id.val,
        };
        const response = await fetch(url, {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(user),
        });
        const resposeData = await response.json();
        if (response.status === 201) {
          emit("refresh-list");
          toast.add({
            severity: "success",
            summary: t("addUser"),
            detail: t("addUserSuccess"),
            life: 3000,
          });
        } else {
          formIsValid.value = false;
          invalidmessage.value = resposeData.message;
          resposeData.data.map((item) => {
            userData[item.param].isValid = false;
            userData[item.param].msg = item.msg;
          });
        }
      } catch (error) {
        //isLoading.value = false;
        formIsValid.value = false;
        invalidmessage.value = error.message;
      }
    };

    onMounted(() => {
      loadUser();
    });

    const loadUser = async () => {
      if (props.userId) {
        try {
          const token = store.getters["user/token"];
          let url = backendURL + "/api/user/get-user/" + props.userId;

          const response = await fetch(url, {
            method: "GET",
            headers: {
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
            },
          });

          const resposeData = await response.json();
          if (response.status === 200) {
            userData.id.val = resposeData.user._id;
            userData.name.val = resposeData.user.name;
            userData.email.val = resposeData.user.email;
            userData.institute.val = resposeData.user.institute;
            userData.userRole.val = resposeData.user.role;
          } else {
            formIsValid.value = false;
            invalidmessage.value = resposeData.message;
          }
        } catch (error) {
          //isLoading.value = false;
          formIsValid.value = false;
          invalidmessage.value = error.message;
        }
      }
    };

    watch(
      () => props.userId,
      () => {
        loadUser(props.userId);
      }
    );

    return {
      userData,
      formIsValid,
      invalidmessage,
      isLoading,
      clearValidity,
      saveUser,
    };
  },
};
</script>
