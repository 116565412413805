<template>
  <div class="bg-primary_50" ref="pageTop">
    <section
      class="bg-cover bg-[url(https://images.unsplash.com/photo-1635741042374-64875ac3ed60?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80)] shadow-lg"
    >
      <div
        class="px-4 py-8 mx-auto max-w-screen-xl items-center lg:items-center lg:flex"
      >
        <h1
          class="uppercase text-2xl font-extrabold sm:text-5xl bg-clip-text flex mx-auto text-center text-primary_50 shadow-xxl"
        >
          {{ $t("admin") }}
        </h1>
      </div>
    </section>
    <new-user-form
      :userId="selectedUserId"
      @refresh-list="refreshList"
    ></new-user-form>
    <section class="mx-4 mt-10">
      <div class="max-w-lg text-left">
        <h1 class="text-2xl font-bold sm:text-3xl text-slate-500">
          {{ $t("userList") }}
        </h1>
      </div>
    </section>
    <section class="mx-4 bg-slate-100 my-6 mb-10">
      <div class="overflow-x-auto">
        <table class="min-w-full text-sm divide-y divide-gray-200">
          <thead>
            <tr>
              <th
                class="p-4 font-medium text-left text-gray-900 whitespace-nowrap"
              >
                <div class="flex items-center">
                  {{ $t("name") }}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-4 h-4 ml-1.5 text-gray-700"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
              </th>
              <th
                class="p-4 font-medium text-left text-gray-900 whitespace-nowrap"
              >
                <div class="flex items-center">
                  {{ $t("emailAddress") }}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-4 h-4 ml-1.5 text-gray-700"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
              </th>

              <th
                class="p-4 font-medium text-left text-gray-900 whitespace-nowrap"
              >
                <div class="flex items-center">
                  {{ $t("institute") }}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-4 h-4 ml-1.5 text-gray-700"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
              </th>

              <th
                class="p-4 font-medium text-left text-gray-900 whitespace-nowrap"
              >
                <div class="flex items-center">
                  {{ $t("role") }}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-4 h-4 ml-1.5 text-gray-700"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
              </th>

              <th
                class="p-4 font-medium text-left text-gray-900 whitespace-nowrap"
              >
                <div class="flex items-center">
                  {{ $t("ststus") }}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-4 h-4 ml-1.5 text-gray-700"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
              </th>
              <th
                class="p-4 font-medium text-left text-gray-900 whitespace-nowrap"
              >
                <div class="flex items-center">
                  {{ $t("edit") }}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-4 h-4 ml-1.5 text-gray-700"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
              </th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-100">
            <user-list-item
              v-for="user in userList"
              :key="user.id"
              :user="user"
              @select-user="loadToEditUser"
            ></user-list-item>
          </tbody>
        </table>
      </div>
    </section>
  </div>
</template>
<script>
import { ref } from "@vue/reactivity";
import { useStore } from "vuex";
import NewUserForm from "./NewUserForm.vue";
import UserListItem from "./UserListItem.vue";
import { onMounted } from "@vue/runtime-core";
import { backendURL } from "@/app-constants";
export default {
  components: { NewUserForm, UserListItem },
  setup() {
    const store = useStore();
    const userList = ref([]);
    const selectedUserId = ref(null);
    const pageTop = ref(null);

    onMounted(() => {
      loadAllUsers();
    });

    const refreshList = () => {
      loadAllUsers();
    };

    const loadAllUsers = async () => {
      const token = store.getters["user/token"];
      try {
        const response = await fetch(backendURL + "/api/user/all-users", {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        });
        const resposeData = await response.json();
        if (!response.ok) {
          console.log("Error");
          console.log(JSON.stringify(resposeData));
        } else {
          userList.value = resposeData.users;
        }
      } catch (error) {
        console.error(error.message);
      }
    };

    const loadToEditUser = (userId) => {
      selectedUserId.value = userId;
      var top = pageTop.value.offsetTop;
      window.scrollTo(0, top);
    };

    return {
      userList,
      refreshList,
      selectedUserId,
      loadToEditUser,
      pageTop,
    };
  },
};
</script>
