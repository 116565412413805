<template>
  <section class="flex flex-col p-4 my-12">
    <img
      class="w-24 mx-auto items-center"
      src="../../assets/images/logo.svg"
      alt=""
    />
    <p class="mx-auto text-center text-sm p-2">WIZEYOU@2022</p>
  </section>
</template>
