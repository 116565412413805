<template>
  <temp-header></temp-header>
  <div>
    <section class="relative flex flex-wrap lg:items-left bg-slate-300">
      <div class="w-full px-4 py-12 lg:w-1/2 sm:px-6 lg:px- sm:py-16 lg:py-8">
        <div class="max-w-lg text-left">
          <h1 class="text-2xl font-bold sm:text-3xl text-slate-500">
            {{ $t("setYourPassword") }}
          </h1>
        </div>
        <form @submit.prevent="setPassword" class="w-full mt-8 mb-0 space-y-4">
          <div>
            <label for="password" class="sr-only">{{ $t("password") }}</label>
            <div class="relative">
              <input
                id="password"
                type="password"
                class="w-full p-4 pr-12 text-sm border-pr rounded-none shadow-sm"
                :class="{
                  'wy-field-validate-error': !loginData.password.isValid,
                }"
                :placeholder="$t('enterPassword')"
                @blur="clearValidity('password')"
                v-model.trim="loginData.password.val"
              />
              <p
                v-if="!loginData.password.isValid"
                class="mt-2 text-sm text-red-600 dark:text-red-500"
              >
                {{ loginData.password.msg }}
              </p>
            </div>
          </div>

          <div>
            <label for="confirmPassword" class="sr-only">{{
              $t("confirmPasswrd")
            }}</label>
            <div class="relative">
              <input
                id="confirmPassword"
                type="password"
                class="w-full p-4 pr-12 text-sm border-pr rounded-none shadow-sm"
                :class="{
                  'wy-field-validate-error': !loginData.confirmPassword.isValid,
                }"
                :placeholder="$t('confirmPassword')"
                @blur="clearValidity('confirmPassword')"
                v-model.trim="loginData.confirmPassword.val"
              />
              <p
                v-if="!loginData.confirmPassword.isValid"
                class="mt-2 text-sm text-red-600 dark:text-red-500"
              >
                {{ loginData.confirmPassword.msg }}
              </p>
            </div>
          </div>
          <div class="flex items-center justify-between">
            <button
              class="uppercase px-12 py-3 text-sm font-lg text-white rounded-none shadow bg-primary_900 sm:w-auto active:bg-primary_700 hover:bg-primary_700 focus:outline-none focus:ring"
              type="submit"
            >
              {{ $t("save") }}
            </button>
          </div>
          <p v-if="!formIsValid" class="text-red-600">
            {{ invalidmessage }}
          </p>
        </form>
      </div>

      <div class="relative w-full h-52 sm:h-48 lg:w-1/2 lg:h-96 mt-8">
        <div>{{ $t("passMust") }}</div>
        <ul>
          <li>
            <span>{{ $t("passwordLaw1") }}</span>
          </li>
          <li>
            <span>{{ $t("passwordLaw2") }}</span>
          </li>
          <li>
            <span>{{ $t("passwordLaw3") }}</span>
          </li>
          <li>
            <span>{{ $t("passwordLaw4") }}</span>
          </li>
        </ul>
      </div>
    </section>
  </div>
  <!--  END CONTACT US/LOGIN SECTION -->
  <the-footer></the-footer>
  <base-spinner v-if="isLoading"></base-spinner>
</template>

<script>
import { reactive, ref } from "@vue/reactivity";
import { useRouter } from "vue-router";
import { backendURL } from "../../app-constants.js";
import BaseSpinner from "../../components/UI/BaseSpinner.vue";
import TheFooter from "../../components/layouts/TheFooter.vue";
import TempHeader from "@/components/layouts/TempHeader.vue";
import { useI18n } from "vue-i18n";
import { useToast } from "primevue/usetoast";

export default {
  components: { BaseSpinner, TempHeader, TheFooter },
  props: ["token"],
  setup(props) {
    const router = useRouter();
    const { t } = useI18n();
    const toast = useToast();

    const loginData = reactive({
      confirmPassword: { val: "", isValid: true, msg: "" },
      password: { val: "", isValid: true, msg: "" },
    });
    const formIsValid = ref(true);
    const invalidmessage = ref(null);
    const isLoading = ref(false);

    const lowermessage = ref(false);
    const uppermessage = ref(false);
    const numbermessage = ref(false);
    const lengthmessage = ref(false);

    function clearValidity(input) {
      loginData[input].isValid = true;
      loginData[input].msg = "";
    }

    function passwordValidity(input) {
      loginData[input].isValid = true;

      var lowerCaseLetters = /[a-z]/g;
      if (loginData.password.val.match(lowerCaseLetters)) {
        lowermessage.value = true;
      } else {
        lowermessage.value = false;
        formIsValid.value = false;
      }

      var upperCaseLetters = /[A-Z]/g;
      if (loginData.password.val.match(upperCaseLetters)) {
        uppermessage.value = true;
      } else {
        uppermessage.value = false;
        formIsValid.value = false;
      }

      var numbers = /[0-9]/g;
      if (loginData.password.val.match(numbers)) {
        numbermessage.value = true;
      } else {
        numbermessage.value = false;
        formIsValid.value = false;
      }

      if (loginData.password.val.length >= 8) {
        lengthmessage.value = true;
      } else {
        lengthmessage.value = false;
        formIsValid.value = false;
      }
    }

    function validateFrom() {
      formIsValid.value = true;
      if (loginData.confirmPassword.val == "") {
        loginData.confirmPassword.isValid = false;
        //loginData.confirmPassword.msg = t("confirmPasswrd");
        formIsValid.value = false;
      }
      if (loginData.password.val == "") {
        loginData.password.isValid = false;
        //loginData.password.msg = t("enterPassword");
        formIsValid.value = false;
      }
    }

    async function setPassword() {
      //isLoading.value = true;
      validateFrom();
      if (!formIsValid.value) {
        invalidmessage.value = t("required_fields_missing");
        return;
      }
      if (loginData.password.val != loginData.confirmPassword.val) {
        isLoading.value = false;
        formIsValid.value = false;
        invalidmessage.value = t("passwordsMissmatch");
        loginData.confirmPassword.msg = t("passwordsMissmatch");
        loginData.confirmPassword.isValid = false;
        return;
      }
      try {
        const response = await fetch(backendURL + "/api/auth/set-password", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token: props.token,
            password: loginData.password.val,
            confirmPassword: loginData.confirmPassword.val,
          }),
        });

        const resposeData = await response.json();

        if (response.status === 200) {
          toast.add({
            severity: "success",
            summary: t("setYourPassword"),
            detail: t("passwordSetSuccess"),
            life: 3000,
          });
          isLoading.value = false;
          router.push("/");
        } else {
          formIsValid.value = false;
          invalidmessage.value = resposeData.message;
          resposeData.data.map((item) => {
            loginData[item.param].isValid = false;
            loginData[item.param].msg = item.msg;
          });
        }
      } catch (error) {
        isLoading.value = false;
        formIsValid.value = false;
        invalidmessage.value = error.message;
      }
    }

    return {
      loginData,
      formIsValid,
      invalidmessage,
      clearValidity,
      setPassword,
      isLoading,
      lowermessage,
      uppermessage,
      numbermessage,
      lengthmessage,
      passwordValidity,
    };
  },
};
</script>
