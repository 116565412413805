<template>
  <div class="dropdown dropdown-end">
    <label tabindex="0" class="btn btn-ghost btn-circle avatar">
      <div class="w-5 rounded-full">
        <img :src="getImgUrl(selectedLang)" />
      </div>
    </label>
    <ul
      tabindex="1"
      class="menu menu-compact w-52 md:w-52 dropdown-content mt-3 shadow bg-primary_50"
    >
      <li
        class="border-b border-secondary_700 d-block"
        v-for="(lang, i) in langs"
        :key="`lang-${i}`"
      >
        <a class="justify-between" @click="onClick(lang)">
          {{ langOptons[lang].name }}
          <div class="w-5 rounded-full">
            <img :src="getImgUrl(lang)" />
          </div>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "SwitchLocale",
  data() {
    return {
      langs: ["en", "sv"],
      langOptons: {
        en: {
          name: "ENGLISH",
          icon: "uk.svg",
        },
        sv: {
          name: "Svenska",
          icon: "sweden.svg",
        },
      },
      selectedLang: "en",
    };
  },
  methods: {
    onChange(e) {
      this.selectedLang = e.target.value;
    },
    onClick(val) {
      this.$i18n.locale = val;
      this.selectedLang = val;
    },
    getImgUrl(lang) {
      return require("@/assets/images/" + this.langOptons[lang].icon);
    },
  },
};
</script>
