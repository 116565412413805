import { createRouter, createWebHistory } from "vue-router";
import store from "../store/index.js";

import commonRouts from "./common-rout";
import adminRouts from "./admin-rout";
import userRout from "./user-rout.js";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [...commonRouts, ...adminRouts, ...userRout],
});

router.beforeEach(function (to, _, next) {
  if (to.meta.requiresAuth && !store.getters["user/isAuthenticated"]) {
    next("/");
  } else {
    next();
  }
});

export default router;
