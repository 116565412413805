import UserContainer from "../views/user/UserContainer.vue";
import UserDashboard from "@/components/user/dashboard/UserDashboard.vue";

export default [
  {
    path: "/user",
    name: "User",
    component: UserContainer,
    children: [
      {
        path: "dashboard",
        component: UserDashboard,
      },
    ],
  },
];
