<template>
  <div class="navbar bg-base-100 px-4">
    <div class="flex-1">
      <img class="w-32" src="../../assets/images/logo.svg" alt="" />
    </div>
    <div class="flex-none">
      <div class="dropdown dropdown-end">
        <label tabindex="0" class="btn btn-ghost btn-circle">
          <div class="indicator">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
              />
            </svg>
            <span class="badge badge-xs badge-primary indicator-item"></span>
          </div>
        </label>
        <div
          tabindex="0"
          class="mt-3 card card-compact dropdown-content w-52 bg-base-100 shadow"
        >
          <div class="card-body">
            <span class="font-bold text-lg uppercase">{{
              $t("notification")
            }}</span>
            <span class="text-info">Notification 1</span>
          </div>
        </div>
      </div>

      <switch-locale></switch-locale>

      <div class="dropdown dropdown-end">
        <label tabindex="0" class="btn btn-ghost btn-circle avatar">
          <div class="w-10 rounded-full">
            <img :src="profImage" />
          </div>
        </label>
        <ul
          tabindex="1"
          class="menu menu-compact w-96 md:w-52 dropdown-content mt-3 shadow bg-primary_50"
        >
          <li class="border-b border-secondary_700 d-block">
            <a class="justify-between"> {{ $t("profile") }} </a>
          </li>
          <li class="border-b border-secondary_700">
            <a> {{ $t("settings") }}</a>
          </li>
          <li>
            <a @click="userSingout">{{ $t("logout") }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { computed } from "@vue/runtime-core";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import SwitchLocale from "../common/SwitchLocale.vue";
export default {
  components: { SwitchLocale },
  setup() {
    const router = useRouter();
    const store = useStore();

    const userSingout = async () => {
      await store.dispatch("user/userLoggedOut");
      router.replace("/");
    };

    const profImage = computed(() => {
      return store.getters["user/usersImage"];
    });

    return {
      userSingout,
      profImage,
    };
  },
};
</script>
