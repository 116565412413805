<template>
  <div class="bg-primary_50">
    <the-header></the-header>
    <user-menu></user-menu>
    <router-view></router-view>
  </div>
</template>
<script>
import TheHeader from "@/components/layouts/TheHeader.vue";
import UserMenu from "@/components/user/UserMenu.vue";
export default {
  components: { TheHeader, UserMenu },
  setup() {},
};
</script>
