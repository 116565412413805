export default {
  setLoggedUser(state, payload) {
    state.loggedUser = payload.loggedUser;
    state.token = payload.token;
    state.base64Image = payload.base64Image;
    state.userId = payload.userId;
    state.role = payload.role;
  },

  updateProfileImage(state, payload) {
    state.base64Image = payload.base64Image;
  },

  updateNotificationCount(state, payload) {
    state.nitificationCount = payload.count;
  },
  updateMessageCount(state, payload) {
    state.messageCount = payload.count;
  },
};
