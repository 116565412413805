import { createApp } from "vue";
import { createI18n } from "vue-i18n";

import "./assets/css/input.css";

import en from "./locals/en";
import sv from "./locals/sv";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import PrimeVue from "primevue/config";
import ConfirmationService from "primevue/confirmationservice";
import ToastService from "primevue/toastservice";

import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import './assets/tailwind.css'

const i18n = createI18n({
  legacy: false,
  locale: "en",
  messages: {
    en: en,
    sv: sv,
  },
});

const app = createApp(App);
app.use(store);
app.use(router);

app.use(i18n);

app.use(PrimeVue);
app.use(ConfirmationService);
app.use(ToastService);

app.mount("#app");
