<template>
  <section
    class="bg-cover bg-[url(https://images.unsplash.com/photo-1635741042374-64875ac3ed60?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80)] shadow-lg"
  >
    <div
      class="px-4 py-8 mx-auto max-w-screen-xl items-center lg:items-center lg:flex"
    >
      <h1
        class="uppercase text-3xl font-extrabold sm:text-5xl bg-clip-text flex mx-auto text-center text-primary_50 shadow-xxl"
      >
        {{ $t("dashboard") }}
      </h1>
    </div>
  </section>

  <div class="mx-4 my-5">
    <div class="mb-8 w-full flex flex-wrap">
      <!-- part2 -->
      <div class="w-full lg:w-3/4">
        <div class="max-w-full">
          <div class="grid md:grid-cols-1 sm:grid-cols-1 mt-3">
            <h1 class="mb-3 text-xl font-bold">MY SOFT SKILLS</h1>
            <div
              class="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-3 sm:mr-3"
            >
              <!-- CARD 1 -->
              <div class="card">
                <h3 class="text-3xl font-bold text-gray_600 p-3">CO</h3>
                <div class="flex justify-center">
                  <span
                    class="p-3 bg-category_1 inline-block rounded-full w-42 h-42 shadow-md"
                  >
                    <img
                      src="../../../assets/images/communication.svg"
                      alt=""
                      class="self-center fill-current w-24 h- mx-auto p-4 color-white"
                    />
                  </span>
                </div>

                <h1 class="text-xl md:text-base py-2 text-center text-gray_600">
                  COMMUNICATION
                </h1>
                <div class="flex justify-center text-gray_500">
                  <label
                    for="my-modal"
                    class="md:hidden px-5 py-2 bg-slate-600 modal-button text-white mx-6 my-2 text-xs"
                    >LEARN</label
                  >
                </div>
              </div>
              <!-- CARD 1 -->
              <div class="card">
                <h3 class="text-3xl font-bold text-gray_600 p-3">CO</h3>
                <div class="flex justify-center">
                  <span
                    class="p-3 bg-category_1 inline-block rounded-full w-42 h-42 shadow-md"
                  >
                    <img
                      src="../../../assets/images/communication.svg"
                      alt=""
                      class="self-center fill-current w-24 h- mx-auto p-4 color-white"
                    />
                  </span>
                </div>

                <h1 class="text-xl md:text-base py-2 text-center text-gray_600">
                  COMMUNICATION
                </h1>
                <div class="flex justify-center text-gray_500">
                  <label
                    for="my-modal"
                    class="md:hidden px-5 py-2 bg-slate-600 modal-button text-white mx-6 my-2 text-xs"
                    >LEARN</label
                  >
                </div>
              </div>
              <!-- CARD 1 -->
              <div class="card">
                <h3 class="text-3xl font-bold text-gray_600 p-3">CO</h3>
                <div class="flex justify-center">
                  <span
                    class="p-3 bg-category_1 inline-block rounded-full w-42 h-42 shadow-md"
                  >
                    <img
                      src="../../../assets/images/communication.svg"
                      alt=""
                      class="self-center fill-current w-24 h- mx-auto p-4 color-white"
                    />
                  </span>
                </div>

                <h1 class="text-xl md:text-base py-2 text-center text-gray_600">
                  COMMUNICATION
                </h1>
                <div class="flex justify-center text-gray_500">
                  <label
                    for="my-modal"
                    class="md:hidden px-5 py-2 bg-slate-600 modal-button text-white mx-6 my-2 text-xs"
                    >LEARN</label
                  >
                </div>
              </div>
              <!-- CARD 1 -->
              <div class="card">
                <h3 class="text-3xl font-bold text-gray_600 p-3">CO</h3>
                <div class="flex justify-center">
                  <span
                    class="p-3 bg-category_1 inline-block rounded-full w-42 h-42 shadow-md"
                  >
                    <img
                      src="../../../assets/images/communication.svg"
                      alt=""
                      class="self-center fill-current w-24 h- mx-auto p-4 color-white"
                    />
                  </span>
                </div>

                <h1 class="text-xl md:text-base py-2 text-center text-gray_600">
                  COMMUNICATION
                </h1>
                <div class="flex justify-center text-gray_500">
                  <label
                    for="my-modal"
                    class="md:hidden px-5 py-2 bg-slate-600 modal-button text-white mx-6 my-2 text-xs"
                    >LEARN</label
                  >
                </div>
              </div>
              <!-- CARD 1 -->
              <div class="card">
                <h3 class="text-3xl font-bold text-gray_600 p-3">CO</h3>
                <div class="flex justify-center">
                  <span
                    class="p-3 bg-category_1 inline-block rounded-full w-42 h-42 shadow-md"
                  >
                    <img
                      src="../../../assets/images/communication.svg"
                      alt=""
                      class="self-center fill-current w-24 h- mx-auto p-4 color-white"
                    />
                  </span>
                </div>

                <h1 class="text-xl md:text-base py-2 text-center text-gray_600">
                  COMMUNICATION
                </h1>
                <div class="flex justify-center text-gray_500">
                  <label
                    for="my-modal"
                    class="md:hidden px-5 py-2 bg-slate-600 modal-button text-white mx-6 my-2 text-xs"
                    >LEARN</label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- MENU -->
      <div class="w-full md:w-full lg:w-1/4 bg-grey mt-3">
        <h1 class="mb-3 text-xl font-bold">MY PROGRESS</h1>

        <a
          href=""
          class="flex flex-col justify-between p-8 bg-white rounded-sm"
        >
          <ul class="steps steps-vertical">
            <li data-content="✓" class="step step-success">AWARENESS</li>
            <li data-content="✓" class="step step-success">AWARENESS</li>
            <li class="step step-primary">RESULTS</li>
            <li class="step">E-</li>
            <li class="step">Receive Product</li>
          </ul>
        </a>
        <a
          href=""
          class="flex flex-col justify-between p-8 mt-3 bg-white rounded-sm shadow-xl transition-shadow group hover:shadow-lg"
        >
          <div>
            <h5 class="text-5xl font-bold text-primary_700">60%</h5>
            <div class="pt-2 mt-4 border-t-2 border-indigo-100">
              <p
                class="text-sm font-medium tracking-widest text-gray-500 uppercase"
              >
                Leadership Soft Skill
              </p>
            </div>
          </div>

          <div class="inline-flex items-center mt-16 text-primary_500">
            <p class="text-lg font-medium text-secondary_900">COMPLETE MORE</p>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-6 h-6 ml-3 transition-transform transform group-hover:translate-x-3"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M17 8l4 4m0 0l-4 4m4-4H3"
              />
            </svg>
          </div>
        </a>
      </div>
    </div>

    <!-- MENU END -->
  </div>
</template>
