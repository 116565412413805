<template>
  <ul
    class="flex border-b bg-gradient-to-r from-primary_900 via-secondary_700 to-purple-600 shadow-lg"
  >
    <li class="flex-1">
      <router-link to="/admin/user-list" class="relative block p-4" href="">
        <div class="flex items-center justify-left">
          <img
            class="w-8 h-8 mr-3"
            src="../../assets/images/creativity.svg"
            alt=""
          />

          <span
            class="text-sm font-medium hidden sm:contents text-white uppercase"
          >
            {{ $t("users") }}</span
          >
        </div>
      </router-link>
    </li>
  </ul>
</template>
