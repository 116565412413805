<template>
  <div class="mt-5">
    <a href="#" class="block rounded-lg shadow-sm shadow-indigo-100">
      <iframe
        class="object-cover"
        width="100%"
        height="280"
        :src="videoDetails.url"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>

      <div class="mt-2">
        <dl>
          <div>
            <dt class="sr-only">{{ $t("author") }}</dt>

            <dd class="text-sm text-gray-500">{{ videoDetails.author }}</dd>
          </div>

          <div>
            <dt class="sr-only">{{ $t("video") }}</dt>

            <dd class="font-medium">{{ videoDetails.video }}</dd>
          </div>
        </dl>
      </div>
    </a>
  </div>
</template>
<script>
export default {
  props: ["videoDetails"],
  setup() {},
};
</script>
