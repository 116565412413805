<template>
  <div class="bg-primary_50" ref="pageTop">
    <temp-header></temp-header>

    <section
      class="text-white bg-gradient-to-r from-black via-blue-900 to-secondary_700"
    >
      <div class="px-4 py-32 mx-auto max-w-screen-xl lg:items-center lg:flex">
        <div class="max-w-3xl mx-auto text-center">
          <h1
            class="text-3xl font-extrabold text-transparent sm:text-5xl bg-clip-text bg-gradient-to-r from-green-300 via-blue-500 to-purple-600"
          >
            {{ $t("identifyYourSoftSkills") }}

            <span class="sm:block"> - {{ $t("improveThem") }} - </span>
          </h1>
        </div>
      </div>
    </section>
    <!-- 
    skills menu -->
    <ul class="flex border-b border-gray-300">
      <li
        class="flex-1"
        v-for="categoty in categoryList"
        :key="categoty.id"
        :class="categoty.colourClass"
      >
        <a class="relative block p-4" :href="'#' + categoty.id">
          <!-- <span class="absolute inset-x-4 w-1/2 h-3 bg-pink-600 -bottom-px"></span> -->

          <div class="flex justify-center">
            <div class="flex items-start justify-center">
              <!-- <span class=' w-8 h-8 md:w-6 md:h-6 bg-category_1 rounded-full mr-2'></span> -->

              <span
                class="ml-3 text-sm font-medium text-white hidden md:contents"
              >
                {{ $t(categoty.nameKey) }}
              </span>
            </div>
          </div>
        </a>
      </li>
    </ul>

    <!-- Skills -->
    <skill-category-view
      v-for="categoty in categoryList"
      :key="categoty.id"
      :categoty="categoty"
    ></skill-category-view>
  </div>
</template>
<script>
import TempHeader from "@/components/layouts/TempHeader.vue";
import { skills } from "../../skill-details.js";
import SkillCategoryView from "@/components/common/landing/SkillCategoryView.vue";
import { ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
export default {
  components: { TempHeader, SkillCategoryView },
  setup() {
    const categoryList = ref(skills);
    const pageTop = ref(null);

    onMounted(() => {
      let top = pageTop.value.offsetTop;
      window.scrollTo(0, top);
    });

    return {
      categoryList,
      pageTop,
    };
  },
};
</script>
