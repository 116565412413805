export const skills = [
  {
    id: 1,
    nameKey: "category1",
    colourClass: "bg-category_1",
    skills: [
      {
        id: 11,
        image: "communication.svg",
        codeKey: "co",
        namekey: "communication",
        descriptionKey: "communicationDis",
      },
      {
        id: 12,
        image: "customer-focus.svg",
        codeKey: "cf",
        namekey: "customerForcus",
        descriptionKey: "customerForcusDis",
      },
      {
        id: 13,
        image: "networking.svg",
        codeKey: "nw",
        namekey: "networking",
        descriptionKey: "networkingDis",
      },
      {
        id: 14,
        image: "teamwork.svg",
        codeKey: "tw",
        namekey: "teamwork",
        descriptionKey: "teamworkDis",
      },

      {
        id: 15,
        image: "motivate-others.svg",
        codeKey: "ma",
        namekey: "motivateOthers",
        descriptionKey: "motivateOthersDis",
      },
    ],
  },
  {
    id: 2,
    nameKey: "category2",
    colourClass: "bg-category_2",
    skills: [
      {
        id: 21,
        image: "decision-making.svg",
        codeKey: "dm",
        namekey: "decisionMaking",
        descriptionKey: "decisionMakingDis",
      },
      {
        id: 22,
        image: "leadership.svg",
        codeKey: "ls",
        namekey: "leadership",
        descriptionKey: "leadershipDis",
      },
      {
        id: 23,
        image: "negotiation.svg",
        codeKey: "ng",
        namekey: "negotiation",
        descriptionKey: "negotiationDis",
      },
      {
        id: 24,
        image: "coaching.svg",
        codeKey: "coa",
        namekey: "coaching",
        descriptionKey: "coachingDis",
      },
      {
        id: 25,
        image: "organisation.svg",
        codeKey: "org",
        namekey: "organisation",
        descriptionKey: "organisationDis",
      },
      {
        id: 26,
        image: "accountability.svg",
        codeKey: "acc",
        namekey: "accountability",
        descriptionKey: "accountabilityDis",
      },
      {
        id: 27,
        image: "problem-solving.svg",
        codeKey: "ps",
        namekey: "problemSolving",
        descriptionKey: "problemSolvingDis",
      },
    ],
  },
  {
    id: 3,
    nameKey: "category3",
    colourClass: "bg-category_3",
    skills: [
      {
        id: 31,
        image: "strategic-thinking.svg",
        codeKey: "st",
        namekey: "strategicThinking",
        descriptionKey: "strategicThinkingDis",
      },
      {
        id: 32,
        image: "critical-thinking.svg",
        codeKey: "ct",
        namekey: "criticalThinking",
        descriptionKey: "criticalThinkingDis",
      },
      {
        id: 33,
        image: "goal-oriented.svg",
        codeKey: "go",
        namekey: "goalOrientation",
        descriptionKey: "goalOrientationDis",
      },
      {
        id: 34,
        image: "personal-development.svg",
        codeKey: "pd",
        namekey: "personalDevelopment",
        descriptionKey: "personalDevelopmentDis",
      },
      {
        id: 35,
        image: "self-management.svg",
        codeKey: "sm",
        namekey: "selfManagement",
        descriptionKey: "selfManagementDis",
      },
      {
        id: 36,
        image: "patience.svg",
        codeKey: "pa",
        namekey: "patience",
        descriptionKey: "patienceDis",
      },
      {
        id: 37,
        image: "self-control.svg",
        codeKey: "sc",
        namekey: "selfControl",
        descriptionKey: "selfControl",
      },
    ],
  },
  {
    id: 4,
    nameKey: "category4",
    colourClass: "bg-category_4",
    skills: [
      {
        id: 41,
        image: "respect-enviornment.svg",
        codeKey: "re",
        namekey: "respectTheEnvironment",
        descriptionKey: "respectTheEnvironmentDis",
      },
      {
        id: 42,
        image: "adaptability.svg",
        codeKey: "ada",
        namekey: "adaptability",
        descriptionKey: "adaptabilityDis",
      },
      {
        id: 43,
        image: "respect-privacy.svg",
        codeKey: "rp",
        namekey: "respectPrivacy",
        descriptionKey: "respectPrivacyDis",
      },
      {
        id: 44,
        image: "positive-attitude.svg",
        codeKey: "poa",
        namekey: "positiveAttitude",
        descriptionKey: "positiveAttitudeDis",
      },
      {
        id: 45,
        image: "conflict-resolution.svg",
        codeKey: "cr",
        namekey: "conflictResolution",
        descriptionKey: "conflictResolutionDis",
      },
      {
        id: 46,
        image: "ethical-behaviour.svg",
        codeKey: "eb",
        namekey: "ethicalBehaviour",
        descriptionKey: "ethicalBehaviourDis",
      },
      {
        id: 47,
        image: "respect-diversity.svg",
        codeKey: "rd",
        namekey: "respectForDiversity",
        descriptionKey: "respectForDiversityDis",
      },
    ],
  },
  {
    id: 5,
    nameKey: "category5",
    colourClass: "bg-category_5",
    skills: [
      {
        id: 51,
        image: "DILIGENCE.svg",
        codeKey: "di",
        namekey: "diligence",
        descriptionKey: "diligenceDis",
      },
      {
        id: 52,
        image: "creativity.svg",
        codeKey: "cre",
        namekey: "creativity",
        descriptionKey: "creativityDis",
      },
      {
        id: 53,
        image: "initiative.svg",
        codeKey: "ini",
        namekey: "initiative",
        descriptionKey: "initiativeDis",
      },
      {
        id: 54,
        image: "tenacity.svg",
        codeKey: "tc",
        namekey: "tenacity",
        descriptionKey: "tenacityDis",
      },
      {
        id: 55,
        image: "motivation.svg",
        codeKey: "mot",
        namekey: "motivation",
        descriptionKey: "motivationDis",
      },
      {
        id: 56,
        image: "resilence.svg",
        codeKey: "res",
        namekey: "resilience",
        descriptionKey: "resilienceDis",
      },
    ],
  },
  {
    id: 6,
    nameKey: "category6",
    colourClass: "bg-category_6",
    skills: [
      {
        id: 61,
        image: "reliability.svg",
        codeKey: "rel",
        namekey: "reliability",
        descriptionKey: "reliabilityDis",
      },
      {
        id: 62,
        image: "efficiency.svg",
        codeKey: "ef",
        namekey: "efficiency",
        descriptionKey: "efficiencyDis",
      },
      {
        id: 63,
        image: "quality.svg",
        codeKey: "mq",
        namekey: "manageQuality",
        descriptionKey: "manageQualityDis",
      },
      {
        id: 64,
        image: "entrepreneurship.svg",
        codeKey: "ent",
        namekey: "entrepereneurship",
        descriptionKey: "entrepereneurshipDis",
      },
    ],
  },
];
