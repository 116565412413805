export const onlineVideoList = [
  {
    id: 1,
    author: "Communication Coach Alex Lyon",
    video: "What Are Soft Skills? Top 8",
    url: "https://www.youtube.com/embed/hZSARM4VaVs",
  },
  {
    id: 2,
    author: "Alux.com",
    video: "15 Timeless Soft Skills You Must Get",
    url: "https://www.youtube.com/embed/NSEBh7Gi9jU",
  },
  {
    id: 3,
    author: "Science of People",
    video: "The Ultimate Guide to Expert Interpersonal Skills",
    url: "https://www.youtube.com/embed/Ib09GqWP5rY",
  },
  {
    id: 4,
    author: "Practical Wisdom - Interesting Ideas",
    video: "8 Important Soft Skills To Excel In Life",
    url: "https://www.youtube.com/embed/Llzf1-ERA7U",
  },
  {
    id: 5,
    author: "Andy Wible | TEDxMuskegon",
    video: "Strengthening Soft Skills",
    url: "https://www.youtube.com/embed/gkLsn4ddmTs",
  },
  {
    id: 6,
    author: "Financial Freedom System",
    video: "Why Soft Skills Matter",
    url: "https://www.youtube.com/embed/edcX5v9UKSY",
  },
];
