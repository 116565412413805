<template>
  <router-view></router-view>
  <ConfirmDialog></ConfirmDialog>
  <Toast />
</template>
<script>
import ConfirmDialog from "primevue/confirmdialog";
import Toast from "primevue/toast";
export default {
  components: { ConfirmDialog, Toast },
  created() {
    this.$store.dispatch("user/autoLogIn");
  },
};
</script>
<style>
@import "https://fonts.googleapis.com/css2?family=Kanit:wght@100;200;300;400;500;600;700;800;900&display=swap";
</style>
