import { backendURL } from "../../../app-constants.js";

export default {
  async login(context, payload) {
    const response = await fetch(backendURL + "/api/auth/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      body: JSON.stringify({
        email: payload.userName,
        password: payload.password,
      }),
    });

    const resposeData = await response.json();
    if (!response.ok) {
      const error = new Error(
        resposeData.message ||
          "Failed to authenticate. Pl. check your credentials."
      );
      throw error;
    }

    localStorage.setItem("token", resposeData.token);
    localStorage.setItem(
      "base64Image",
      backendURL + "/" + resposeData.base64Image
    );
    localStorage.setItem("userId", resposeData.userId);
    localStorage.setItem("role", resposeData.role);

    context.commit("setLoggedUser", {
      token: resposeData.token,
      base64Image: backendURL + "/" + resposeData.base64Image,
      userId: resposeData.userId,
      role: resposeData.role,
    });
  },

  autoLogIn(context) {
    const token = localStorage.getItem("token");
    const base64Image = localStorage.getItem("base64Image");
    const userId = localStorage.getItem("userId");
    const role = localStorage.getItem("role");

    if (userId && token && base64Image && role) {
      context.commit("setLoggedUser", {
        token: token,
        base64Image: base64Image,
        userId: userId,
        role: role,
      });
    }
  },

  userLoggedOut(context) {
    localStorage.removeItem("token");
    localStorage.removeItem("base64Image");
    localStorage.removeItem("userId");
    localStorage.removeItem("role");

    context.commit("setLoggedUser", {
      token: null,
      base64Image: null,
      userId: null,
      role: null,
    });
  },

  updateProfileImage(context, payload) {
    localStorage.setItem("base64Image", payload.base64Image);
    context.commit("updateProfileImage", payload);
  },

  async updateNotification(context) {
    const response = await fetch(
      backendURL +
        "/api/auth/notificationCountUnread/" +
        localStorage.getItem("userId"),
      {
        method: "GET",
        headers: {
          //"Content-Type": "application/json",
        },
      }
    );

    const resposeData = await response.json();
    if (response.ok) {
      if (resposeData.valid) {
        context.commit("updateNotificationCount", { count: resposeData.data });
      }
    }
  },

  async updateMessages(context) {
    const response = await fetch(
      backendURL +
        "/api/auth/messageCountUnread/" +
        localStorage.getItem("userId"),
      {
        method: "GET",
        headers: {
          //"Content-Type": "application/json",
        },
      }
    );

    const resposeData = await response.json();
    if (response.ok) {
      if (resposeData.valid) {
        context.commit("updateMessageCount", { count: resposeData.data });
      }
    }
  },
};
