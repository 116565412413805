export default {
  getstart: "Get started today!",
  home: "HOME",
  about: "ABOUT",
  contact: "CONTACT",
  team: "TEAM",
  logIn: "LOG IN",

  login: "Login",
  email: "Email",
  password: "Password",
  password_val: "Enter password",
  fogotPassword: "Forgot Password",
  submit: "LOG IN",
  failedToAuthenticate: "Failed to authenticate",
  enterEmail: "Enter email",
  enterPassword: "Enter password",
  recoverNow: "Recover now",

  notification: "Notification",
  profile: "Profile",
  settings: "Settings",
  logout: "Logout",

  learn: "Learn",
  learnSoftSkills: "Learn Soft Skills?",
  category1: "Category 1 ",
  category2: "Category 2",
  category3: "Category 3",
  category4: "Category 4",
  category5: "Category 5",
  category6: "Category 6",

  co: "co",
  communication: "Communication",
  communicationDis:
    "Effectively exchange information and ideas through verbal, non-verbal, visual and written means with others; alter communication style for different situations, people and mediums; listen to understand; clarify understanding; take different viewpoints into consideration",

  cf: "cf",
  customerForcus: "Customer forcus",
  customerForcusDis:
    "aware of customer needs; respond to demands in timely manner; aware of key business drivers; provides optimum service to clients; focus on customer satisfaction.",

  nw: "nw",
  networking: "Networking",
  networkingDis:
    "Reach out to and meet up with people in a professional context; build a web of personal professional contacts; stay in touch with contacts with common interests and at influential positions in organisations; return favours; be open to help others without expecting to get someting back",

  tw: "tw",
  teamwork: "Teamwork",
  teamworkDis:
    "Work confidently within a group, assuming relevant role, to achieve both personal and collective goals; balance own contribution and success against others for benefit of the team; share the workload appropriately; recognise the value of other people’s contributions and ideas; recognise and respect the role of others; support division or co-ownership of responsibilities",

  ma: "mo",
  motivateOthers: "Motivate others",
  motivateOthersDis:
    "Get to know what drives and stimulates individuals to achieve goals and personal growth; demonstrate self-motivation and professionalism to inspire people; set realistic goals; engage in transparent planning of work with a clear division of tasks; ensure people understand how their role contributes to achieving corporate goals; encourage people by showing you appreciate their efforts and praising them for good work done; demonstrate people they have your trust; listen attentively and value other peoples opinion; empower people to play a more active role in an organisation.",

  dm: "dm",
  decisionMaking: "Decision making",
  decisionMakingDis:
    "Weigh pros and cons in balanced way; reason logically; deductive; deliberate over arguments; exercise judgement based on situation, evidence, empathy; be pragmatic",

  ls: "ld",
  leadership: "Leadership",
  leadershipDis:
    "Guide, direct others to common goal by example and instruction; supervise, motivate and plan work for employees or teams; create and enforce time schedules, goals, metrics to maximise output; trust colleagues; work collabratively with team mates; delegate within capability, responsibilty parameters",

  ng: "no",
  negotiation: "Negotiation",
  negotiationDis:
    "Communicate with others to reach a suitable outcome for all; build a common understanding; resolve a point of difference; stay focused on own and others' intentions or goals; demonstrate willingness to compromise; apply active listening to understand other's intentions or goalds; build and adapt argumentation according to changing circumstances to improve chances for win-win solution",

  coa: "ch",
  coaching: "Coaching",
  coachingDis:
    "Teach, guide others to act effectively, empathise; suggest optimum course of action; demonstrate and model best practice; guide by provision of relevant knowledge and support; prescribe exercises, drills to reinforce learning; acknowledge, praise, reward good practice and competence mastery",

  org: "og",
  organisation: "Organisation",
  organisationDis:
    "Schedule and optimise activities and available resources; monitor and evaluate progress; set priorities priorities; coordinate work to accomplish an objective; break down tasks into smaller compenents and delegate when possible",

  acc: "ab",
  accountability: "Accountability",
  accountabilityDis:
    "Get work done while operating alone, with little supervision; monitor and evaluate own performance; be aware of own strengths / weaknesses.",

  ps: "ps",
  problemSolving: "Problem solving",
  problemSolvingDis:
    "Reason inductively, deductively; use systems thinking; analyse, evaluate alternative solutions; plan, conduct investigations; interpret information; draw conclusions; make decisions based on best analysis",

  st: "st",
  strategicThinking: "Strategic thinking",
  strategicThinkingDis:
    "Shape a vision for the future and be able to translate it into concrete ideas and plans; prepare strategies and come up with ideas and plans that can cope with changing environments and various challenges ahead; formulate achievable and cost-effective plans that address organisational goals and strategies; communicate a clear, vivid and relevant description or picture of where the organisation should be 3, 5 or 10 years  ",

  ct: "ct",
  criticalThinking: "Critical thinking",
  criticalThinkingDis:
    "Ask appropriate questions to elicit qulaity information for a purpose; interpret information in context; be aware of 'fake News' phenomen; appraise, analyse scenarios; exercise situational judgement",

  go: "go",
  goalOrientation: "Goal orientation",
  goalOrientationDis:
    "Demonstrate strong ambition to succesfully complete tasks; define strategies and plans effective for achieving the pursued objectives; give priority to actions that contribute to achieve pursued objectives;  demonstrate focus on high performance and quality levels; seek continuous performance improvement",

  pd: "pd",
  personalDevelopment: "Personal development",
  personalDevelopmentDis:
    "Understand own learning needs to progress in life, work and study; set goals to realise and maximise own potential; take control of own personal growth; embrace new experiences and learn from them; engage in continuous learning to develop, maintain and improve skills and knowledge; demonstrate a growing autonomy in acquiring knowledge, know-how and new behaviours.",

  sm: "sm",
  selfManagement: "Self-management",
  selfManagementDis:
    "Perform, complete assigned tasks in a goal-oriented manner; take ownership; accept liability for professional decisions, involving self or others; realise that choices and actions have positive and negative consequences; recognise limits to autonomy; understand when to seek help",

  pa: "pt",
  patience: "Patience",
  patienceDis:
    "Accept or tolerate delay, trouble or suffering without getting angry or upset; remain composed when faced with emotional or conflict situations; give people the space to make mistakes and learn from the experience",

  sc: "sc",
  selfControl: "Self-control",
  selfControlDis:
    "Regulate own emotions, thoughts, and behaviours in the face of temptations and impulses; control impulse; stop and think before acting; manage feelings by thinking about goals to keep going when faced with upsetting and unexpected circumstances; control body movements.",

  re: "re",
  respectTheEnvironment: "Respect the environment",
  respectTheEnvironmentDis:
    "Respect home, workplace, neighbourhood environment proactively; show interest in nature, conservation, ecology; model positive environmental behaviour; enact environmentally friendly principles, policies, regulations in the workplace; promote environmental sustainability in society",

  ada: "ad",
  adaptability: "Adaptability",
  adaptabilityDis:
    "Demonstrate openness to change when faced with changing demands and circumstances; adjust plans, goals, behaviours and actions to effectively and successfully cope with change; demonstrate flexibility when coping with various circumstances. ",

  rp: "rp",
  respectPrivacy: " Respect privacy",
  respectPrivacyDis:
    "Be aware of high level provisions of GDPR; recognise the right to keep personal information private; respect, protect personal data; disclose personal information only to authorised people; be discreet; keep secrets; inform others of rights to privacy",

  poa: "pa",
  positiveAttitude: "Positive attitude",
  positiveAttitudeDis:
    "Display energy, enthusiasm, passion, optimism and positive thinking; keep up the good spirit and believe in oneself and others, even in challenging situations",

  cr: "cr",
  conflictResolution: "Conflict resolution",
  conflictResolutionDis:
    "Identify, prevent conflict; be diplomatic, sensitive; intervene, resolve conflicts or disputes in, proactive, peaceful manner; reduce negative impact of conflict; promote positive outcomes; create restorative working environment ",

  eb: "eb",
  ethicalBehaviour: "Ethical behaviour",
  ethicalBehaviourDis:
    "Act according to accepted principles of right and wrong; apply criteria of fairness, transparency and impartiality, in work practices, when dealing with people; respect human rights; be generous; honest; act with empathy; be socially oriented; act according to honourable principles.",

  rd: "rd",
  respectForDiversity: "Respect for diversity",
  respectForDiversityDis:
    "Respect different cultural, social and sexual affinities; respond inclusively, equitably to all; actively promote social justice, confront discrimination in the workplace and society.",

  di: "dg",
  diligence: "Diligence",
  diligenceDis:
    "Demonstrate precision and accuracy in work; work in disciplined manner; demonstrate willingnes to work hard; stay with tasks until completed, even when others give up",

  cre: "cv",
  creativity: "Creativity",
  creativityDis:
    "Suggest new ways of doing things; improvise, experiment confidently; be inspired by novelty; leverage experience for guidance; is in awe of new disciplines, topics; actively seek out, explore new areas; be curious, open-minded",

  ini: "it",
  initiative: "Initiative",
  initiativeDis:
    "Act without direction; is conscientious, motivated to innovate, invent; is energetic, dynamic in approach to tasks, routines; proceed in entrepreneurial fashion; approaches tasks, challenges proactively; is courageous; continuously seek new and challening opportunities; take risks",

  te: "tc",
  tenacity: "Tenacity",
  tenacityDis:
    "Stick to one's tasks achieving goals despite fatigue, frustration, failure and setbacks; meet personal commitments; demonstrates will to continue when work  becomes difficult; goes above and beyond expectations; display firmness of character; demonstrate indomitable spirit; strive for excellence.",

  mot: "mv",
  motivation: "Motivation",
  motivationDis:
    "The drive to perform a task and achieve results with enthusiasm, determination and autonomy; do what needs to be done, without influence from other people;  find a reason and strength to complete a task, even when challenging, without giving up or needing others to encourage them",

  res: "rs",
  resilience: "Resilience",
  resilienceDis:
    "Handle challenges, disruption and change and recover from set-backs and adversity; tolerate and work constructively within unexpected, unpredictable and complex situations; stay calm and react in a constructive way to own or others' anger or when faced with obstacles or complaints; adapt to accommodate modifications in the workplace",

  rel: "rl",
  reliability: "Reliability",
  reliabilityDis:
    "act in a dependable, honest and trustworthy way; be loyal, authentic, consistent in actions and relationships.",

  ef: "ef",
  efficiency: "Efficiency",
  efficiencyDis:
    "Plan effectively; use timely productively; be competent in performing tasks; be economical in use of resources or time; work in logical sequence; seek help; collaborate when appropriate",

  mq: "mq",
  manageQuality: "Manage quality",
  manageQualityDis:
    "Promote excellence in workplace processes, activities, deliverables; give relevant, constructive feedback on processes, products; establish procedures to prevent errors; pose appropriate questions to elicit accurate information for a purpose; encourage effcient, effective work practices; appraise, modify behaviour, performance",

  ent: "en",
  entrepereneurship: "Entrepereneurship",
  entrepereneurshipDis:
    "Act upon ideas and opportunities to transform them, over time, into cultural, financial or social value for others",

  dashboard: "Customer forcus",

  userList: "User List",
  notActivated: "Not activated",
  active: "Active",
  inactive: "Inactive",
  admin: "Admin",
  name: "Name",
  emailAddress: "Email Address",
  institute: "Institute",
  role: "Role",
  ststus: "Status",
  edit: "Edit",
  addUser: "Add User",
  enterName: "Enter Name",
  enterInstitute: "Enter institute",
  enterRole: "Select Role",
  user: "User",
  save: "Save",
  required_fields_missing: "Required fields missing",
  addUserSuccess: "User saved successfully",
  users: "Users",
  selectRole: "Select a user role",
  setYourPassword: "Set your password",
  confirmPasswrd: "Confirm password",
  passMust: "Password must contain the following:",
  passwordLaw1: "Minimum 8 characters",
  passwordLaw2: "A capital (uppercase) letter",
  passwordLaw3: "A number",
  passwordLaw4: "A lowercase letter",
  passwordsMissmatch: "Passwords missmatch",
  passwordSetSuccess: "Password set successfully",
  learnSoftSkills1: "Learn soft skills",
  onlineVideos: "Online videos",
  author: "Author",
  video: "Video",
  identifyYourSoftSkills: "Identify your Soft skills",
  improveThem: "Improve them",
  lpTitle1:
    "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!",
  getStarted: "Get Started",
  learnMore: "Learn More",
  aboutSoftSkills: "About Soft Skills",
  waht: "What",
  wahtDescription:
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem beatae possimus in ducimus rem quibusdam quos explicabo vero corporis illo numquam tenetur voluptas dicta dolorum provident incidunt porro eaque, amet nam similique, harum distinctio aspernatur sint nisi. Excepturi, soluta id?",
  why: "Why",
  whyDescription1:
    "According to the UNESCO, the Soft Skills (also known as Non-Cognitive Skills) are”patterns of thought, feelings and behaviours” (Borghans et al., 2008) that are socially determined and can be developed throughout the lifetime to produce value.",
  whyDescription2:
    "Soft Skills can comprise personality traits, motivations and attitudes and are vitally important for the employability and adaptability of European citizens.",
  how: "How",
  howDescription:
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem beatae possimus in ducimus rem quibusdam quos explicabo vero corporis illo numquam tenetur voluptas dicta dolorum provident incidunt porro eaque, amet nam similique, harum distinctio aspernatur sint nisi. Excepturi, soluta id?",
  identifyYourself: "identify yourself",
  learnExplore36: "Learn/ explore all the 36 soft skills",
  identifyYourselfDesc:
    "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Omnis perferendis hic asperiores quibusdam quidem voluptates doloremque reiciendis nostrum harum. Repudiandae?",
  top10Most: "Top 10 most",
  demandingSoftSkills: " Demanding Soft skills for future job market",
  top10MostDescription:
    "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Omnis perferendis hic asperiores quibusdam quidem voluptates doloremque reiciendis nostrum harum. Repudiandae?",
  theTop16: "The Top 16",
  inDemandSoft: "In-demand Soft Skills for 2022 You Need to Master",
  theTop16Description:
    "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Omnis perferendis hic asperiores quibusdam quidem voluptates doloremque reiciendis nostrum harum. Repudiandae?",
};
