export default {
  getstart: "Get started today!",
  home: "HOME",
  about: "ABOUT",
  contact: "CONTACT",
  team: "TEAM",
  logIn: "LOG IN",

  login: "Login",
  email: "Email",
  password: "Password",
  fogotPassword: "Forgot Password",
  submit: "LOG IN",
  failedToAuthenticate: "Failed to authenticate",
  enterEmail: "Enter email",
  enterPassword: "Enter password",
  recoverNow: "Recover now",

  notification: "Underrättelse",
  profile: "Profil",
  settings: "inställningar",
  logout: "Logga ut",

  dashboard: "instrumentbräda",
};
