import AdminContainer from "../views/admin/AdminContainer.vue";
import UserList from "../components/admin/user/UserList.vue";

export default [
  {
    path: "/admin",
    name: "Adnin",
    component: AdminContainer,
    children: [{ path: "user-list", component: UserList }],
  },
];
