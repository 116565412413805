<template>
  <!-- <temp-header></temp-header> -->
  <!-- SIGNIN SECTION -->
  <section id="contact" class="get-started">
    <div class="container set-password">
      <!-- SIGNIN FORM -->
      <div class="row align-items-center full-h">
        <div
          class="col-12 col-lg-6 p-3 d-none d-sm-block justify-content-middle"
        >
          <div class="login-info w-100">
            <!-- <img
              src="../../assets/img/lock.png"
              class="d-block mx-lg-auto img-fluid"
              alt="Thesis Pro"
              width="500"
              height="400"
              loading="lazy"
            /> -->
          </div>
        </div>
        <div class="col-12 col-lg-6 justify-content-middle">
          <h3 class="mb-4 ti">{{ $t("fp.fogotPass") }}</h3>
          <div class="form form-area">
            <div class="col-12 justify-content-middle">
              <div class="message">
                <span class="text-start mb-3">
                  {{ $t("fp.message") }}
                </span>
              </div>
            </div>
            <form
              @submit.prevent="resetPassword"
              autocomplete="off"
              class="row"
            >
              <div class="row g-3">
                <div
                  class="alert alert-info alert-dismissible fade show col-12"
                  role="alert"
                  v-if="!formIsValid"
                >
                  <span class="comman-error">{{ invalidmessage }}</span>
                </div>
                <div
                  class="col-12"
                  :class="{ errors: !regData.emailAddress.isValid }"
                >
                  <label for="emailAddress" class="form-label"
                    >{{ $t("fp.email") }} <span class="text-muted"></span
                  ></label>
                  <input
                    type="email"
                    id="emailAddress"
                    name="emailAddress"
                    v-model="regData.emailAddress.val"
                    class="form-control"
                    @blur="clearValidity('emailAddress')"
                  />
                  <div class="error-message">
                    {{ $t("fp.email_val") }}
                  </div>
                </div>
                <div class="text-center mt-4">
                  <button class="btn btn-primary" type="submit">
                    {{ $t("fp.sendIns") }}
                    <i class="fas fa-paper-plane"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- END SIGNIN FORM -->
    </div>
  </section>
  <!--  END CONTACT US/LOGIN SECTION -->
  <!-- <the-footer></the-footer> -->
  <base-spinner v-if="isLoading"></base-spinner>
</template>

<script>
import { backendURL } from "../../app-constants.js";
import BaseSpinner from "../../components/UI/BaseSpinner.vue";
// import TheFooter from "../../components/layouts/TheFooter.vue";
// import TempHeader from "@/components/layouts/TempHeader.vue";
import { reactive, ref } from "@vue/reactivity";
import { useI18n } from "vue-i18n";

export default {
  components: { BaseSpinner },
  setup() {
    const { t } = useI18n();

    const regData = reactive({
      emailAddress: { val: "", isValid: true },
    });
    const formIsValid = ref(true);
    const invalidmessage = ref(null);
    const isLoading = ref(false);

    function clearValidity(input) {
      regData[input].isValid = true;
    }

    function validateFrom() {
      formIsValid.value = true;
      if (regData.emailAddress.val == "") {
        regData.emailAddress.isValid = false;
        formIsValid.value = false;
      }
    }

    async function resetPassword() {
      //isLoading.value = true;
      validateFrom();

      if (!formIsValid.value) {
        isLoading.value = false;
        invalidmessage.value = "Required  fields missing";
        return;
      }
      try {
        const response = await fetch(backendURL + "/api/auth/resetPassword", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: regData.emailAddress.val,
          }),
        });

        const resposeData = await response.json();

        if (!response.ok) {
          const error = new Error(
            resposeData.message || "Error Reset Password"
          );
          throw error;
        }

        if (resposeData.valid) {
          isLoading.value = false;
          formIsValid.value = false;
          invalidmessage.value = t("fp.successMessage");
        } else {
          const error = new Error(resposeData.message);
          throw error;
        }
      } catch (error) {
        isLoading.value = false;
        formIsValid.value = false;
        invalidmessage.value = error.message;
      }
    }

    return {
      regData,
      formIsValid,
      invalidmessage,
      clearValidity,
      resetPassword,
      isLoading,
      t,
    };
  },
};
</script>
