<template>
  <div class="bg-primary_50">
    <temp-header></temp-header>
    <sign-in-form></sign-in-form>
    <the-footer></the-footer>
  </div>
</template>

<script>
import TempHeader from "@/components/layouts/TempHeader.vue";
import SignInForm from "../../components/common/SignInForm.vue";
import TheFooter from "@/components/layouts/TheFooter.vue";

export default {
  props: ["role"],
  components: {
    TempHeader,
    SignInForm,
    TheFooter,
  },
  methods: {
    mounted() {
      //this.$gAuth.signOut();
    },
  },
};
</script>
